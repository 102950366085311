var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "b-row",
        { staticClass: "mt-2" },
        [
          _c(
            "b-col",
            { attrs: { cols: "12" } },
            [
              _c("label", [_vm._v(" Search for a User ")]),
              _c(
                "b-input-group",
                [
                  _c("b-input", {
                    attrs: {
                      placeholder: "Email address",
                      state: _vm.errorState,
                    },
                    on: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.searchForUser()
                      },
                    },
                    model: {
                      value: _vm.searchEmail,
                      callback: function ($$v) {
                        _vm.searchEmail = $$v
                      },
                      expression: "searchEmail",
                    },
                  }),
                  _c(
                    "b-input-group-append",
                    [
                      _c(
                        "b-btn",
                        {
                          attrs: {
                            variant: "success",
                            disabled: _vm.searchEmail.length < 1,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.searchForUser()
                            },
                          },
                        },
                        [_c("fa-icon", { attrs: { icon: "search" } })],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-form-invalid-feedback",
                { attrs: { state: _vm.errorState } },
                [_vm._v(" That user was not found ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }