var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-card",
        {
          staticClass: "mt-2",
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function () {
                return [
                  _c(
                    "b-row",
                    {
                      attrs: {
                        "align-h": "around",
                        "align-v": "center",
                        "no-gutters": "",
                      },
                    },
                    [
                      _c(
                        "b-col",
                        { attrs: { cols: "6", sm: "4", md: "3", lg: "2" } },
                        [_c("span", [_vm._v(_vm._s(_vm.title))])]
                      ),
                      _c("b-col", {
                        attrs: { cols: "auto", sm: "4", md: "6", lg: "8" },
                      }),
                      _c(
                        "b-col",
                        { attrs: { cols: "6", sm: "4", md: "3", lg: "2" } },
                        [
                          _c(
                            "b-btn",
                            {
                              directives: [
                                {
                                  name: "b-modal",
                                  rawName: "v-b-modal.logger-add-user",
                                  modifiers: { "logger-add-user": true },
                                },
                              ],
                              staticClass: "float-right",
                              attrs: {
                                disabled: !_vm.controlsVisible,
                                variant: "success",
                              },
                            },
                            [
                              _vm._v(" Add User "),
                              _c("fa-icon", { attrs: { icon: "plus" } }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("b-table", {
            ref: "userTable",
            attrs: {
              fields: _vm.fields,
              items: _vm.providerFunction,
              busy: _vm.isBusy,
              responsive: "",
            },
            on: {
              "update:busy": function ($event) {
                _vm.isBusy = $event
              },
            },
            scopedSlots: _vm._u([
              {
                key: "cell(name)",
                fn: function (data) {
                  return [
                    _vm.admin
                      ? _c(
                          "b-link",
                          { attrs: { to: "/oem/user/" + data.item.id } },
                          [_vm._v(" " + _vm._s(data.value) + " ")]
                        )
                      : _c("span", [_vm._v(" " + _vm._s(data.value) + " ")]),
                    data.item.owner
                      ? _c("b-badge", { attrs: { variant: "success" } }, [
                          _vm._v(" owner "),
                        ])
                      : _vm._e(),
                  ]
                },
              },
              {
                key: "cell(writeAccess)",
                fn: function (data) {
                  return [
                    !data.item.owner
                      ? _c("b-checkbox", {
                          attrs: {
                            value: true,
                            unchecked_value: false,
                            checked: data.value,
                            disabled: !_vm.controlsVisible,
                          },
                          on: {
                            change: function ($event) {
                              return _vm.setWriteAccess(
                                data.item.id,
                                !data.value
                              )
                            },
                          },
                        })
                      : _vm._e(),
                  ]
                },
              },
              {
                key: "cell(id)",
                fn: function (data) {
                  return [
                    _vm.controlsVisible && data.item.owner
                      ? _c(
                          "b-btn",
                          {
                            directives: [
                              {
                                name: "b-modal",
                                rawName: "v-b-modal.logger-transfer-owner",
                                modifiers: { "logger-transfer-owner": true },
                              },
                              {
                                name: "b-popover",
                                rawName: "v-b-popover.hover",
                                value: "Transfer Ownership",
                                expression: "'Transfer Ownership'",
                                modifiers: { hover: true },
                              },
                            ],
                            attrs: { variant: "danger" },
                          },
                          [_c("fa-icon", { attrs: { icon: "exchange-alt" } })],
                          1
                        )
                      : _vm.controlsVisible
                      ? _c(
                          "b-btn",
                          {
                            directives: [
                              {
                                name: "b-popover",
                                rawName: "v-b-popover.hover",
                                value: "Remove User Access",
                                expression: "'Remove User Access'",
                                modifiers: { hover: true },
                              },
                            ],
                            attrs: { variant: "danger" },
                            on: {
                              click: function ($event) {
                                return _vm.deleteUserAccess(data.value)
                              },
                            },
                          },
                          [_c("fa-icon", { attrs: { icon: "trash" } })],
                          1
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "logger-add-user",
            title: "Add User to Logger",
            "ok-title": "Grant Access",
            "ok-variant": "success",
            "ok-disabled": !this.addUserSearch.name,
            "cancel-variant": "primary",
          },
          on: {
            ok: function ($event) {
              $event.preventDefault()
              return _vm.addUserAction.apply(null, arguments)
            },
          },
        },
        [
          _c("user-search-box", { on: { input: _vm.addUserSearchInput } }),
          _c(
            "b-row",
            { staticClass: "mt-3" },
            [
              _c(
                "b-col",
                { attrs: { cols: "12" } },
                [
                  _vm._v(" Name: "),
                  _c("span", { staticClass: "text-muted" }, [
                    _vm._v(_vm._s(_vm.addUserSearch.name)),
                  ]),
                  _c("br"),
                  _vm._v(" Email: "),
                  _c("span", { staticClass: "text-muted" }, [
                    _vm._v(_vm._s(_vm.addUserSearch.email)),
                  ]),
                  _c("br"),
                  _vm._v(" Company: "),
                  _c("span", { staticClass: "text-muted" }, [
                    _vm._v(_vm._s(_vm.addUserSearch.company)),
                  ]),
                  _c("br"),
                  _vm._v(" Write Access: "),
                  _c("b-checkbox", {
                    staticStyle: { display: "inline" },
                    attrs: { disabled: !_vm.addUserSearch.name },
                    model: {
                      value: _vm.addUser.writeAccess,
                      callback: function ($$v) {
                        _vm.$set(_vm.addUser, "writeAccess", $$v)
                      },
                      expression: "addUser.writeAccess",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "logger-transfer-owner",
            title: "Transfer Ownership",
            "ok-title": "Transfer",
            "ok-variant": "danger",
            "ok-disabled":
              !this.transferOwnerSearch.name || !_vm.transferOwner.confirmed,
            "cancel-variant": "secondary",
          },
          on: {
            ok: function ($event) {
              $event.preventDefault()
              return _vm.transferOwnerAction.apply(null, arguments)
            },
          },
        },
        [
          _c("user-search-box", { on: { input: _vm.transferUserSearchInput } }),
          _c(
            "b-row",
            { staticClass: "mt-3", attrs: { "align-h": "center" } },
            [
              _c("b-col", { staticClass: "ml-5", attrs: { cols: "12" } }, [
                _vm._v(" Name: "),
                _c("span", { staticClass: "text-muted" }, [
                  _vm._v(_vm._s(_vm.transferOwnerSearch.name)),
                ]),
                _c("br"),
                _vm._v(" Email: "),
                _c("span", { staticClass: "text-muted" }, [
                  _vm._v(_vm._s(_vm.transferOwnerSearch.email)),
                ]),
                _c("br"),
                _vm._v(" Company: "),
                _c("span", { staticClass: "text-muted" }, [
                  _vm._v(_vm._s(_vm.transferOwnerSearch.company)),
                ]),
                _c("br"),
              ]),
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "mt-4" },
            [
              _c(
                "b-col",
                { attrs: { cols: "12" } },
                [
                  _c("p", [
                    _vm._v(
                      " After transferring this logger, you will remain as a user with write access. "
                    ),
                  ]),
                  _c(
                    "b-checkbox",
                    {
                      model: {
                        value: _vm.transferOwner.confirmed,
                        callback: function ($$v) {
                          _vm.$set(_vm.transferOwner, "confirmed", $$v)
                        },
                        expression: "transferOwner.confirmed",
                      },
                    },
                    [
                      _vm._v(
                        " I confirm that I understand that this action is not reversable and will result in all data associated with this user residing within the full control of the user listed above. "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "logger-page-refresh",
            "hide-header": "",
            "body-class": "lead text-center",
            "hide-footer": "",
            "hide-header-close": "",
            "no-close-on-backdrop": "",
            "no-close-on-esc": "",
            centered: "",
          },
        },
        [
          _vm._v(" Transfer complete."),
          _c("br"),
          _vm._v(" Please wait while the page reloads"),
          _c("br"),
          _c("b-spinner", { attrs: { variant: "primary" } }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }